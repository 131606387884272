
import { Component, Vue } from 'vue-property-decorator';
import rulesService from '@/services/rules.service';
import DeleteDialog from '@/components/dialogs/delete-dialog.component.vue';
import AlertError from '@/components/alert-error/alert-error.component.vue';
import ProductInterestService from '../../services/product-interest.service';
import CompaniesService from '../../../Companies/services/companies.service';
import { ProductInterest } from '../../entities/product-interest.entity';
import { Company } from '../../../Companies/entities/company.entity';

@Component({ components: { DeleteDialog } })
export default class ProductInterestCreateComponent extends Vue {
  public $refs: any;

  private productInterest: ProductInterest = new ProductInterest();

  private showDialogDelete: boolean = false;

  private companies: Company[] = [];

  private errors: any[] = [];

  private status: any[] = [
    {
      id: 0,
      name: 'Inativo',
    },
    {
      id: 1,
      name: 'Ativo',
    },
  ];

  private rules: any = {
    required: rulesService.required,
  };

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  private get labelFormType() {
    return this.isEdit ? 'Editar' : 'Cadastrar';
  }

  private get isEdit() {
    const { id } = this.$route.params;
    return !!id;
  }

  private setShowDialogDelete(productInterest: ProductInterest) {
    this.showDialogDelete = true;
  }

  private closeDialogDelete() {
    this.showDialogDelete = false;
  }

  private deleteItem() {
    this.productInterest
      .delete()
      .then(() => {
        this.$snackbar.open({
          text: 'Produto de interesse excluído com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.goToList();
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: err.response.data.message,
          color: 'danger',
          buttonColor: '#fff',
        });
        throw new Error(err);
      })
      .finally(() => {
        this.closeDialogDelete();
      });
  }

  private async getProductInterestById() {
    const { id } = this.$route.params;
    const productInterest = await ProductInterestService.getProductInterestById(id);
    this.productInterest = new ProductInterest(productInterest);
    this.$loader.close();
  }

  private async submit() {
    if (!this.$refs.form.validate()) return;
    if (this.isEdit) {
      this.edit();
      return;
    }
    this.save();
  }

  private async save() {
    this.productInterest
      .save()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Produto de interesse cadastrado com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.$router.push({
          name: 'product-interest-list',
        });
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Erro ao cadastrar produto de interesse',
          color: 'danger',
          buttonColor: '#fff',
        });
        this.errors = err.response.data.errors;
      });
  }

  private async edit() {
    this.productInterest
      .update()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Produto de interesse alterado com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.$router.push({
          name: 'product-interest-list',
        });
      })
      .catch((err: any) => {
        this.errors = err.response.data.errors;
      });
  }

  private async goToList() {
    this.$router.push({
      name: 'product-interest-list',
    });
  }

  private async setCompanyId(companyId: string) {
    this.productInterest.companyId = companyId;
  }

  private async getCompanies() {
    const companies: Company[] = await CompaniesService.getAllCompanies();
    this.companies = companies.map(company => new Company(company));
  }

  private created() {
    this.getCompanies();
    if (this.isEdit) {
      this.getProductInterestById();
    }
  }
}
